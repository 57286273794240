import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-st.-louis-missouri.png'
import image0 from "../../images/cities/scale-model-of-mississippi-river-overlook-in-st.-louis-missouri.png"
import image1 from "../../images/cities/scale-model-of-old-post-office-plaza-in-st.-louis-missouri.png"
import image2 from "../../images/cities/scale-model-of-anheuser-busch-st.-louis-brewery-in-st.-louis-missouri.png"
import image3 from "../../images/cities/scale-model-of-laclede's-landing-wax-museum-in-st.-louis-missouri.png"
import image4 from "../../images/cities/scale-model-of-herbaria-in-st.-louis-missouri.png"
import image5 from "../../images/cities/scale-model-of-chatillon-demenil-mansion-in-st.-louis-missouri.png"
import image6 from "../../images/cities/scale-model-of-tower-grove-house-in-st.-louis-missouri.png"
import image7 from "../../images/cities/scale-model-of-old-courthouse-in-st.-louis-missouri.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='St. Louis'
            state='Missouri'
            image={image}
            lat='38.6357'
            lon='-90.2446'
            attractions={ [{"name": "Mississippi River Overlook", "vicinity": "185 W Trendley Ave, East St Louis", "types": ["point_of_interest", "establishment"], "lat": 38.622375, "lng": -90.17543409999996}, {"name": "Old Post Office Plaza", "vicinity": "800 Locust St, St. Louis", "types": ["point_of_interest", "establishment"], "lat": 38.6291215, "lng": -90.19279849999998}, {"name": "Anheuser-Busch St. Louis Brewery", "vicinity": "1200 Lynch St, St. Louis", "types": ["food", "point_of_interest", "establishment"], "lat": 38.5992341, "lng": -90.21434399999998}, {"name": "Laclede's Landing Wax Museum", "vicinity": "720 N 2nd St, St. Louis", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.6307668, "lng": -90.1842557}, {"name": "Herbaria", "vicinity": "2016 Marconi Ave, St. Louis", "types": ["store", "point_of_interest", "establishment"], "lat": 38.616166, "lng": -90.27256599999998}, {"name": "Chatillon-DeMenil Mansion", "vicinity": "3352 Demenil Pl, St. Louis", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.592507, "lng": -90.21606500000001}, {"name": "Tower Grove House", "vicinity": "St. Louis", "types": ["point_of_interest", "establishment"], "lat": 38.6115006, "lng": -90.25835510000002}, {"name": "Old Courthouse", "vicinity": "11 N 4th St, St. Louis", "types": ["point_of_interest", "establishment"], "lat": 38.625674, "lng": -90.18927400000001}] }
            attractionImages={ {"Mississippi River Overlook":image0,"Old Post Office Plaza":image1,"Anheuser-Busch St. Louis Brewery":image2,"Laclede's Landing Wax Museum":image3,"Herbaria":image4,"Chatillon-DeMenil Mansion":image5,"Tower Grove House":image6,"Old Courthouse":image7,} }
       />);
  }
}